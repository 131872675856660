export const paths = {
    index: '/',
    auth: {
        login: '/auth/login',
        register: '/auth/register',
        action: '/auth/action',
    },
    icons: '/icons',
    account: '/account',
    organisation: '/organisation',
    assets: {
        new: '/asset/new',
        list: '/asset/list',
        drafts: '/asset/list?status=Draft',
        view: (assetId: string) => `/asset/view/${assetId}`,
    },
    projects: {
        list: '/project/list',
        view: (projectId: string) => `/project/view/${projectId}`,
    },
    analytics: '/analytics',
    smartAccess: '/smart-access',
    verify: '/verify',
    peers: {
        list: '/peers/list',
    },
    docs: '/',
    401: '/401',
    404: '/404',
    500: '/500',
    superAdmin: {
        list: '/super-admin/list'
    },
    integrations: {
        git: '/integrations/git',
    },
};
