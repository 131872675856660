export enum Provider {
    GitHub = 'github',

    // thinking big...
    GitLab = 'gitlab',
    Bitbucket = 'bitbucket'
}

export enum ProcessingStatus {
    Pending = 'pending',
    InProgress = 'in_progress',
    Completed = 'completed',
    Failed = 'failed'
}

export interface StatusDetails {
    status: ProcessingStatus;
    lastUpdated: Date;
    message?: string;
    startedAt?: Date;
    finishedAt?: Date;
}

export interface GitFileChange {
    pushLatestSha: string;
    filename: string;
    hash: string;
    processingStatus: StatusDetails;
    changeType: string;
    commit: {
        sha: string;
        message: string;
        date: Date;
    }
}

export interface Commit {
    id?: string;
    message?: string;
    added?: string[];
    removed?: string[];
    modified?: string[];
    url?: string;
    author: {
        name?: string;
        email?: string | null;
        username?: string;
    };
}

export interface GitPush {
    _id: string;
    organisation: string;
    provider: Provider;
    createdAt: Date;
    updatedAt: Date;
    ref: string;
    head_commit: Commit;
    latestSha: string;
    processingStatus: StatusDetails;
    sender: {
        login?: string;
        html_url?: string;
        avatar_url?: string;
    };
    changedFiles: GitFileChange[];
}
