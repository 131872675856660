import CheckCircle from "@untitled-ui/icons-react/build/esm/CheckCircle";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";
import Clock from "@untitled-ui/icons-react/build/esm/Clock";
import { SeverityPill } from "components/severity-pill";
import { CircularProgress } from "@mui/material";
import { ProcessingStatus } from "types/integrations/git";
import { FC } from "react";

const PushStatus: FC<{ status: ProcessingStatus }> = ({ status }) => {
    switch (status) {
        case ProcessingStatus.InProgress:
            return (
                <SeverityPill color="primary">
                    <CircularProgress
                        size={12}
                        thickness={3}
                        color="inherit"
                        sx={{ mr: 0.5 }} />
                    In Progress
                </SeverityPill>
            );
        case ProcessingStatus.Pending:
            return (
                <SeverityPill color="info">
                    <Clock width={15} style={{ marginRight: 3 }} />
                    Pending
                </SeverityPill>
            );
        case ProcessingStatus.Completed:
            return (
                <SeverityPill color="success">
                    <CheckCircle width={15} style={{ marginRight: 3 }} />
                    Completed
                </SeverityPill>
            );
        case ProcessingStatus.Failed:
            return (
                <SeverityPill color="error">
                    <AlertCircle width={15} style={{ marginRight: 3 }} />
                    Failed
                </SeverityPill>
            );
        default:
            return (
                <SeverityPill color="error">
                    Unknown
                </SeverityPill>
            );
    }
};

export default PushStatus;